
.contact-info-list a {
    display: flex;
    align-items: center;
}



/* Target the input field of type "number" */
input[type="number"] {
    /* Disable the up and down arrows */
    -webkit-appearance: textfield; /* Safari-specific */
    -moz-appearance: textfield; /* Firefox-specific */
    appearance: textfield; /* Standard */
  }
  
  /* Target the spinner arrows */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none; /* Safari-specific */
    appearance: none; /* Standard */
    margin: 0; /* Remove default margin */
  }
  