.select-buttons {
    margin-left: 0 !important;
    width: 30%;
}

.select-buttons li {
    flex-grow: 1; 
    flex-basis: 0; 
    min-width: 0;
}

.select-buttons li a {
    cursor: pointer;
}

.select-buttons li a:hover {
    cursor: pointer;
}


@media only screen and (max-width: 500px) {

    .select-buttons {
        width: 100% !important;
    }

    .select-buttons li {
        min-width: 100%;
    }
  }