.f-logo {
    width: 5rem;
    margin-left: auto;
    margin-right: auto;
}

.footer-widget .f-logo {
    margin-bottom: 5px!important;
}

.footer-contact, .footer-social ul {
    justify-content: center;
}